/*! Michael Pumo - helpers.js */

function domReady (fn) {
  if (document.readyState !== 'loading') {
    console.log('Ready state not ready yet...', document.readyState)
    fn()
    return
  }
  document.addEventListener('DOMContentLoaded', fn)
}

function randomNumber (from, to) {
  return Math.floor(Math.random() * to) + from
}

export {
  randomNumber,
  domReady
}
