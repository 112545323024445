import hamburger from './modules/hamburger'
import header from './modules/header'
import videos from './modules/videos'
import inview from './modules/inview'
import jump from './modules/jump'
import lazyLoad from './modules/lazyload'
import rellax from './modules/rellax'
import slider from './modules/slider'
import videofullscreen from './modules/video_fullscreen/'


function appHeight () {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

function init () {
  hamburger()
  // header()
  videos()
  inview()
  jump()
  lazyLoad()
  // rellax()
  slider()
  videofullscreen()

  // Sets our custom CSS property to window height for iOS and mobile usage.
  appHeight()


  window.addEventListener('resize', appHeight)
}

export default init
