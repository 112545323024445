
// function init () {
//   const vids = document.querySelectorAll('.c-media__iframe')

//   vids.forEach((vid) => {
		
//     const val_src = vid.dataset.src;
//     const val_width = vid.dataset.width;
//     const val_height = vid.dataset.height;

//     if(window.innerWidth < 768) {
//       if(vid.getAttribute("data-srcmobile")) {
//         const val_src = vid.dataset.srcmobile;
//         const val_width = vid.dataset.widthmobile;
//         const val_height = vid.dataset.heightmobile;
//       }
//     }

//     var iframe = document.createElement('iframe');

//     iframe.width = val_width;
//     iframe.height = val_height;
//     iframe.src = val_src;

//     vid.appendChild(iframe)
//   })
// }

// export default init


import $ from 'jquery';

function init () {

	$('iframe.c-media__iframe').each(function(){

		$(this).on('inview', function(event, isInView) {
			var $this = $(this);

			var jqueryPlayer = new Vimeo.Player($this.attr('id'));

		  if (isInView) {
		  	jqueryPlayer.play();
		  } else {
		    // element has gone out of viewport
		  	jqueryPlayer.pause();
		  }
		});
	});
	
}


export default init
