import Jump from 'jump.js'
import mq from '../../utilities/breakpoints'

function init () {
  const anchors = document.querySelectorAll('a')

  if (!anchors.length) {
    return
  }

  const jumps = Array.from(anchors)
    .filter(item => item.getAttribute('href').charAt(0) === '#')

  if (!jumps.length) {
    return
  }

  Array.from(jumps).forEach(jump => {
    jump.addEventListener('click', (e) => {
      e.preventDefault()
      const el = jump.getAttribute('href')
      jumpTo(el)
    })
  })
}

function jumpTo (el) {
  Jump(el, {
    duration: 700,
    offset: 0
    // offset: (mq.is('large')) ? -74 : -64
  })
}

export default init
