function init () {
  window.lastPosition = 0
  setClasses()
  window.addEventListener('scroll', setClasses)
}

function getCurrentPosition () {
  let currentPosition = window.pageYOffset || document.documentElement.scrollTop
  currentPosition = (currentPosition < 0) ? 0 : currentPosition
  return currentPosition
}

function getLastPosition () {
  return window.lastPosition
}

function setLastPosition (position) {
  window.lastPosition = position
}

function setClasses () {
  const html = document.querySelector('html')
  const header = document.querySelector('#header')
  const lastPosition = getLastPosition()
  const currentPosition = getCurrentPosition()

  if (currentPosition > lastPosition && lastPosition > 1) {
    html.classList.remove('is-scrolling-up')
    html.classList.add('is-scrolling-down')
  } else {
    html.classList.remove('is-scrolling-down')
    html.classList.add('is-scrolling-up')
  }

  const offset = (header) ? header.offsetHeight : 0

  if (window.pageYOffset > offset) {
    html.classList.add('has-scrolled-down')
  } else {
    html.classList.remove('has-scrolled-down')
  }

  setLastPosition(currentPosition)
}

export default init
