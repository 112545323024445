/*! Michael Pumo - app.js */

// App CSS.
import '../css/app.scss'
import './utilities/pace'
import './utilities/jqueryinview'

// App JS.
import { domReady } from './utilities/helpers'
import framework from './framework'

function start () {
  framework()
}

domReady(start)
