import $ from 'jquery';

function init () {

  if($(".videobg-aspect").length > 0) {

    videoSizes()

    // on resize
    $(window).resize(function(){
      videoSizes()
    });
  }

}

function videoSizes(){
  var $videoBgAspect = $(".videobg-aspect:visible");
  var $videoBgWidth = $(".videobg-width:visible");

  var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();
  var windowAspect = ($(window).height() / $(window).width());
  if (windowAspect > videoAspect) {
    $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
  } else {
    $videoBgWidth.width(100 + "%")
  }
}

export default init
