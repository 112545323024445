const body = document.body
const button = document.querySelectorAll('.hamburger')
const buttonToggle = document.querySelectorAll('.toggleNav')
const menu_area = document.getElementById('menu')

function menu () {
	button.forEach(function(btn) {
        btn.classList.toggle('is-active')
    })
	body.classList.toggle('has-navigation')
}

function init () {
	if (!button) {
		return
	}

	buttonToggle.forEach(function(btn) {
		btn.addEventListener('click', menu)
	})

	menu_area.addEventListener('click', function(e){
		e.stopPropagation();
		buttonToggle.forEach(function(btn) {
        	btn.classList.remove('is-active')
        })
		body.classList.remove('has-navigation')
	})

	document.onkeydown = function(evt) {
	    evt = evt || window.event;
	    var isEscape = false;
	    if ("key" in evt) {
	        isEscape = (evt.key === "Escape" || evt.key === "Esc");
	    } else {
	        isEscape = (evt.keyCode === 27);
	    }
	    if (isEscape) {
	        buttonToggle.forEach(function(btn) {
	        	btn.classList.remove('is-active')
	        })
			body.classList.remove('has-navigation')
	    }
	}
}

export default init
