import $ from 'jquery';
import 'slick-carousel';

function init () {

  $('.c-slider').each(function(slider){
    var slider = $(this);
    var delay = slider.attr('data-delay');
    var fade = slider.attr('data-fade');
    var autoplaySpeed = (delay && delay.length) ? parseInt(delay, 10) : 5000;

    if(slider.find('.c-slider__item').length > 1) {

      slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){

        // only the current vid (desktop and mobile)
        if(slick.$slides.eq(nextSlide).find('video').length > 0) {
          slick.$slides.eq(nextSlide).find('video').each(function(){
            var videoID  = $(this).attr('id');
            var video = document.getElementById(videoID);
            // video.pause();
            video.play();
            video.pause();
            video.currentTime = 0;
          });
        }
        var customSpeed = slick.$slides.eq(nextSlide).find('.c-slider__item').data('custom-speed');
        if(customSpeed) {
          slider.slick('slickSetOption', 'autoplaySpeed', customSpeed);
        } else {
          slider.slick('slickSetOption', 'autoplaySpeed', autoplaySpeed);
        }
      }).on('afterChange', function(event, slick, currentSlide, nextSlide){

        // play current video
        if(slick.$slides.eq(currentSlide).find('video').length > 0) {
          slick.$slides.eq(currentSlide).find('video').each(function(){
            var videoID  = $(this).attr('id');
            var video = document.getElementById(videoID);
            video.play();
          });
        }
      }).slick({
        autoplay: true,
        autoplaySpeed: autoplaySpeed,
        arrows: false,
        draggable: false,
        fade: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
      }).slick('slickPause');

      setTimeout(function() {
        slider.slick('slickPlay');
      }, delay);

    }
  })

  // setup()
}

// function setup () {
//   const sliders = document.querySelectorAll('.c-slider')

//   if (!sliders.length) {
//     return
//   }

  // sliders.forEach((slider) => {
  //   const { delay, fade } = slider.dataset
  //   // console.log('fade', fade, fade.length)
  //   return new window.Flickity(slider, {
  //     adaptiveHeight: false,
  //     autoPlay: (delay && delay.length) ? parseInt(delay, 10) : 5000,
  //     pauseAutoPlayOnHover: false,
  //     cellAlign: 'center',
  //     cellSelector: '.c-slider__item',
  //     draggable: false,
  //     fade: true,
  //     selectedAttraction: (fade && fade.length) ? 0.1 : 0,
  //     friction: (fade && fade.length) ? 1 : 0,
  //     lazyload: false,
  //     prevNextButtons: false,
  //     pageDots: false,
  //     // wrapAround: true
  //     on: {
  //       // ready: function() {
  //         // console.log('Flickity is ready')
  //       // },
  //       change: function( index ) {
  //         // console.log( 'Slide changed to' + index )
  //         // if (document.querySelector('.home') !== null) {
  //         //   // .. homepage
  //         //   const section1 = document.getElementById('section-1');
  //         //   section1.setAttribute('data-theme', document.getElementsByClassName('c-slider__item')[index].dataset.color);
  //         // }
  //       }
  //     }
  //   })
  // })

// }

export default init
