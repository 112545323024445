const mq = {

  is (s) {
    const size = s.trim()
    const sizes = {
      xsmall: '480px',
      small: '576px',
      medium: '768px',
      large: '992px',
      xlarge: '1200px',
      xxlarge: '1600px'
    }

    if (sizes.hasOwnProperty(size)) {
      return window.matchMedia(`only screen and (min-width: ${size})`).matches
    }

    throw new ReferenceError(`The size ${size} is not a valid breakpoint: ${JSON.stringify(sizes)}`)
  }

}

export default mq
